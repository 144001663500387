<template>
  <div>
    <van-nav-bar left-arrow left-text="返回" @click-left="goBack" title="网点榜单" />

    <div style="background-color:#fff;margin-top:17px">
      <van-cell title="选择日期" :value="date" @click="show = true" is-link />
      <van-calendar v-model="show" @confirm="onConfirm" :min-date="minDate" />
    </div>
    <!-- 电子支付金额-列表 -->
    <div style="margin-top:18px;background-color:#fff;padding-top:6px">
      <div class="tit">电子支付金额</div>
      <van-row style="padding:0 16px">
        <van-col span="2"><span class="listTitle">排名</span></van-col>
        <van-col span="10"><span class="listTitle">网点</span></van-col>
        <van-col span="6" @click="insFn"><span class="listTitle">安装支付
            <van-icon :name="isIns?'arrow-down':'arrow-up'" />
          </span>
        </van-col>
        <van-col @click="repairFn" span="6">
          <span class="listTitle">维修支付
            <van-icon :name="isrepair?'arrow-down':'arrow-up'" />
          </span>
        </van-col>
      </van-row>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished">
          <van-cell v-for="(item,i) in list" :key="i">
            <van-row>
              <van-col span="2">{{i+1}}</van-col>
              <van-col span="10">
                <div class="listTitle">{{item.name}}</div>
                <div class="listTitle">{{item.webNumber}}</div>
              </van-col>
              <van-col span="6">
                <div class="listTitle">{{item.insAmount}}</div>
              </van-col>
              <van-col span="6">
                <div class="listTitle">{{item.repAmount}}</div>
              </van-col>
            </van-row>
          </van-cell>
          <template slot="finished">
            <van-button @click="dzzfjeFn" v-if="list.length>0" type="default" size="small" icon="arrow" icon-position="right">查看全部</van-button>
            <span v-else>暂无数据</span>
          </template>
        </van-list>
      </van-pull-refresh>

    </div>
    <!-- 安装完工工单-列表 -->
    <div style="margin-top:18px;background-color:#fff;padding-top:6px">
      <div class="tit">安装完工工单</div>
      <van-row style="padding:0 16px">
        <van-col span="2"><span class="listTitle">排名</span></van-col>
        <van-col span="10"><span class="listTitle">网点</span></van-col>
        <van-col span="6" @click="insFn"><span class="listTitle">工单量
            <van-icon :name="isIns?'arrow-down':'arrow-up'" />
          </span>
        </van-col>
        <van-col @click="repairFn" span="6">
          <span class="listTitle">设备台数
            <van-icon :name="isrepair?'arrow-down':'arrow-up'" />
          </span>
        </van-col>
      </van-row>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished">
          <van-cell v-for="(item,i) in list" :key="i">
            <van-row>
              <van-col span="2">{{i+1}}</van-col>
              <van-col span="10">
                <div class="listTitle">{{item.name}}</div>
                <div class="listTitle">{{item.webNumber}}</div>
              </van-col>
              <van-col span="6">
                <div class="listTitle">{{item.install}}</div>
              </van-col>
              <van-col span="6">
                <div class="listTitle">0</div>
              </van-col>
            </van-row>
          </van-cell>
          <template slot="finished">
            <van-button @click="azwggdFn" v-if="list.length>0" type="default" size="small" icon="arrow" icon-position="right">查看全部</van-button>
            <span v-else>暂无数据</span>
          </template>
        </van-list>
      </van-pull-refresh>
    </div>
    <!-- 维修完工工单-列表 -->
    <div style="margin-top:18px;background-color:#fff;padding-top:6px">
      <div class="tit">维修完工工单</div>
      <van-row style="padding:0 16px">
        <van-col span="2"><span class="listTitle">排名</span></van-col>
        <van-col span="10"><span class="listTitle">网点</span></van-col>
        <van-col span="6" @click="insFn">
          <span class="listTitle">工单量
            <van-icon :name="isIns?'arrow-down':'arrow-up'" />
          </span>
        </van-col>
        <van-col @click="repairFn" span="6">
          <span class="listTitle">设备台数
            <van-icon :name="isrepair?'arrow-down':'arrow-up'" />
          </span>
        </van-col>
      </van-row>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished">
          <van-cell v-for="(item,i) in list" :key="i">
            <van-row>
              <van-col span="2">{{i+1}}</van-col>
              <van-col span="10">
                <div class="listTitle">{{item.name}}</div>
                <div class="listTitle">{{item.webNumber}}</div>
              </van-col>
              <van-col span="6">
                <div class="listTitle">{{item.repair}}</div>
              </van-col>
              <van-col span="6">
                <div class="listTitle">0</div>
              </van-col>
            </van-row>
          </van-cell>
          <template slot="finished">
            <van-button @click="wxwggdFn" v-if="list.length>0" type="default" size="small" icon="arrow" icon-position="right">查看全部</van-button>
            <span v-else>暂无数据</span>
          </template>
        </van-list>
      </van-pull-refresh>
    </div>
    <!-- 工单好评率-列表 -->
    <!-- <div style="margin-top:18px;background-color:#fff;padding-top:6px">
      <div class="tit">工单好评率</div>
      <van-row style="padding:0 16px">
        <van-col span="2"><span class="listTitle">排名</span></van-col>
        <van-col span="10"><span class="listTitle">网点</span></van-col>
        <van-col span="6" @click="insFn">
          <span class="listTitle">评价率
            <van-icon :name="isIns?'arrow-down':'arrow-up'" />
          </span>
        </van-col>
        <van-col @click="repairFn" span="6">
          <span class="listTitle">好评率
            <van-icon :name="isrepair?'arrow-down':'arrow-up'" />
          </span>
        </van-col>
      </van-row>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished">
          <van-cell v-for="(item,i) in list" :key="i">
            <van-row>
              <van-col span="2">{{i+1}}</van-col>
              <van-col span="10">
                <div class="listTitle">{{item.webName}}</div>
                <div class="listTitle">{{item.websit}}</div>
              </van-col>
              <van-col span="6">
                <div class="listTitle">{{item.ctorder}}</div>
              </van-col>
              <van-col span="6">
                <div class="listTitle">0</div>
              </van-col>
            </van-row>
          </van-cell>
          <template slot="finished">
            <van-button v-if="list.length>0" type="default" size="small" icon="arrow" icon-position="right">查看全部</van-button>
            <span v-else>暂无数据</span>
          </template>
        </van-list>
      </van-pull-refresh>
    </div> -->
    <!-- 工单差评率-列表 -->
    <!-- <div style="margin-top:18px;background-color:#fff;padding-top:6px">
      <div class="tit">工单差评率</div>
      <van-row style="padding:0 16px">
        <van-col span="2"><span class="listTitle">排名</span></van-col>
        <van-col span="10"><span class="listTitle">网点</span></van-col>
        <van-col span="6" @click="insFn"><span class="listTitle">评价率
            <van-icon :name="isIns?'arrow-down':'arrow-up'" />
          </span>
        </van-col>
        <van-col @click="repairFn" span="6">
          <span class="listTitle">差评率
            <van-icon :name="isrepair?'arrow-down':'arrow-up'" />
          </span>
        </van-col>
      </van-row>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished">
          <van-cell v-for="(item,i) in list" :key="i">
            <van-row>
              <van-col span="2">{{i+1}}</van-col>
              <van-col span="10">
                <div class="listTitle">{{item.webName}}</div>
                <div class="listTitle">{{item.websit}}</div>
              </van-col>
              <van-col span="6">
                <div class="listTitle">{{item.ctorder}}</div>
              </van-col>
              <van-col span="6">
                <div class="listTitle">0</div>
              </van-col>
            </van-row>
          </van-cell>
          <template slot="finished">
            <van-button v-if="list.length>0" type="default" size="small" icon="arrow" icon-position="right">查看全部</van-button>
            <span v-else>暂无数据</span>
          </template>
        </van-list>
      </van-pull-refresh>
    </div> -->
  </div>
</template>

<script>
import { getUrlKey } from "@/utils/common";
export default {
  data() {
    return {
      show: false,
      date: "",
      minDate: new Date(2020, 1, 1),
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      isIns: false,
      isrepair: false,
    };
  },
  created() {
    this.getDate();
    this.getList();
  },
  methods: {
    //维修完工工单-跳转
    wxwggdFn() {
      this.$router.push({
        path: "/masterFinished/repaireDetail",
        query: {
          time: this.date,
        },
      });
    },
    //安装完工工单-跳转
    azwggdFn() {
      this.$router.push({
        path: "/masterFinished/installDetail",
        query: {
          time: this.date,
        },
      });
    },
    //电子支付金额-跳转
    dzzfjeFn() {
      this.$router.push({
        path: "/analysis/allNetWork",
        query: {
          date: this.date,
        },
      });
    },
    //维修筛选
    repairFn() {},
    //安装筛选
    insFn() {},
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
    //获取今天日期
    getDate() {
      let time = new Date();
      this.date = ` ${time.getFullYear()}/${
        time.getMonth() + 1 > 9
          ? time.getMonth() + 1
          : "0" + (time.getMonth() + 1)
      }/${time.getDate() > 9 ? time.getDate() : "0" + time.getDate()}`;
    },
    //获取数据
    async getList() {
      let params = {
        openid: getUrlKey("openid", window.location.href),
        moduleId: "11",
        type: "家用",
        startDate: this.date,
        endDate: this.date,
      };
      this.value = params;
      let res = await this.postRequest("/wechat-analysis/fees-day", params);
      let websitArr = res.data.map((v) => {
        return v.websit;
      });
      websitArr = [...new Set(websitArr)].slice(0, 5);
      let list = [];
      for (let i = 0; i < websitArr.length; i++) {
        list[i] = {
          webNumber: websitArr[i],
          name: "",
          install: 0,
          repair: 0,
          insAmount: 0,
          repAmount: 0,
        };
        for (let j = 0; j < res.data.length; j++) {
          if (websitArr[i] === res.data[j].websit) {
            list[i].name = res.data[j].webName;
          }
          if (
            websitArr[i] === res.data[j].websit &&
            res.data[j].btype === "材料"
          ) {
            list[i].install = res.data[j].ctorder;
            list[i].insAmount = res.data[j].amount;
          }
          if (
            websitArr[i] === res.data[j].websit &&
            res.data[j].btype === "维修"
          ) {
            list[i].repair = res.data[j].ctorder;
            list[i].repAmount = res.data[j].amount;
          }
        }
      }
      // this.list = list;
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      if (res.data.length === 0) {
        this.list = [];
        this.finished = true;
      }
      this.list = list;
      this.loading = false;
      if (this.list.length >= websitArr.length) {
        this.finished = true;
      }
    },
    onConfirm(date) {
      this.show = false;
      this.date = this.formatDate(date);
      this.getList();
    },
    //日期格式化
    formatDate(date) {
      return `${date.getFullYear()}/${
        date.getMonth() + 1 >= 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)
      }/${date.getDate() >= 10 ? date.getDate() : "0" + date.getDate()}`;
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang='scss' scoped>
.tit {
  font-weight: 700;
  font-size: 14px;
  padding: 10px 10px;
}
.listTitle {
  font-weight: 700;
  font-size: 12px;
}
</style>